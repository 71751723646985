import * as React from "react";
import DipraBreadcrumbs from "../components/Layout/Breadcrumbs";
import { JoinMailingListButton } from "../components/ui/News/NewsAndCommentaryPage/NewsAndCommentaryPage";
import ArchiveList from "../components/ui/News/NewsAndCommentaryPage/NewsArchive";
import PageHeader from "../components/ui/PageHeader";
import get from "lodash/get";
import { PaginationNav } from "../components/ui/PaginationNav";
import { WmkSeo } from "wmk-seo";
import { graphql, useStaticQuery } from "gatsby";

const NewsArchivePage = ({ pageContext }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            baseUrl
            title
            twitter
          }
        }
      }
    `
  );
  return (
    <>
      <WmkSeo.Meta
        title={
          pageContext?.isDipraMinuteArchive
            ? `Dipra Minute Archives Page ${pageContext?.page}`
            : `WATER INDUSTRY NEWS, OPINION AND COMMENTARY Archives Page ${pageContext?.page}`
        }
        siteTitle={site.siteMetadata.title}
        baseUrl={site.siteMetadata.baseUrl}
        slug={"/archives"}
        path={
          pageContext?.isDipraMinuteArchive
            ? "/news/dipra-minute"
            : "/news/news-and-commentary"
        }
        ogImageUrl={""}
        twitterImageUrl={""}
        twitterHandle={site.siteMetadata.twitter}
      />
      <DipraBreadcrumbs
        route={
          pageContext?.isDipraMinuteArchive
            ? "/news/dipra-minute/archives"
            : "/news/news-and-commentary/archives"
        }
      />
      <PageHeader
        subhead={"News"}
        headline={
          pageContext?.isDipraMinuteArchive
            ? "Dipra Minute Archives"
            : "WATER INDUSTRY NEWS, OPINION AND COMMENTARY Archives"
        }
      />
      <JoinMailingListButton />
      <ArchiveList archives={get(pageContext, `list`, [])} />
      <PaginationNav
        route={
          pageContext?.isDipraMinuteArchive
            ? "/news/dipra-minute/archives"
            : "/news/news-and-commentary/archives"
        }
        currentPage={get(pageContext, `page`, 0)}
        totalPages={get(pageContext, `pages`, 0)}
      />
    </>
  );
};

export default NewsArchivePage;
